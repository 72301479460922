export default function ({ req, store }) {
    if (process.server) {
        const clientIp =
            req.headers['x-forwarded-for'] || // Falls hinter einem Proxy
            req.connection.remoteAddress ||  // Standard-Remote-Adresse
            req.socket?.remoteAddress ||     // Fallback für ältere Node.js-Versionen
            null;

        // IP speichern, z. B. im Vuex-Store
        store.commit('SET_CLIENT_IP', clientIp);
    }
}
